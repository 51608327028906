import { UserEventList } from '../../components/Event';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import { useTitle } from '../../hooks/useTitle';
import { makeTitle } from '../../utils/common';

// eslint-disable-next-line import/no-default-export
export default function MyEventList(): JSX.Element | null {
  useTitle(makeTitle('My Events'));
  return (
    <PublicHomeLayout>
      <UserEventList />
    </PublicHomeLayout>
  );
}
