import { Link } from '@remix-run/react';
import useSWR from 'swr';
import { match } from 'ts-pattern';

import { apiService } from '../../services/api-service';
import { type GamePack } from '../../types/game';
import { err2s } from '../../utils/common';
import { GamePackCover } from '../Game/Utilities';
import { Loading } from '../Loading';
import { useMyOrgId } from '../Organization';
import { EventRow } from './EventRow';

function ScheduledEventList() {
  const swr = useSWR('/my/events/upcoming', async () => {
    const resp = await apiService.event.getMyUpcomingEvents();
    return resp.data.events;
  });

  return (
    <div className='w-245 text-white mt-10'>
      <header className='flex justify-between'>
        <div className='text-3.5xl font-bold'>Scheduled Experiences</div>
        <Link to='./create' className='h-10 px-5 btn-primary flex items-center'>
          Schedule Event
        </Link>
      </header>
      <main className='w-full py-10 items-center justify-center'>
        {match(swr)
          .when(
            () => swr.isLoading,
            () => <Loading />
          )
          .when(
            () => !!swr.error,
            () => (
              <div className='text-sms text-red-002'>{err2s(swr.error)}</div>
            )
          )
          .otherwise(() => (
            <div className='flex flex-col items-center gap-2.5'>
              {swr.data?.map((e) => (
                <EventRow key={e.id} event={e} />
              ))}
              {swr.data?.length === 0 && (
                <div>No events scheduled. What are you waiting for?!</div>
              )}
            </div>
          ))}
      </main>
    </div>
  );
}

function PurchasedGamePackRow(props: { pack: GamePack }) {
  const { pack } = props;
  return (
    <div
      className='
        flex items-center w-full h-25 bg-modal
        border border-secondary rounded-xl px-2 gap-4
      '
    >
      <div className='w-40'>
        <GamePackCover pack={pack} />
      </div>
      <div className='flex flex-col w-98'>
        <div className='font-bold'>{pack.name}</div>
      </div>
      <div className='ml-auto'>
        <Link
          to={`/events/create?game-pack-id=${pack.id}`}
          className='flex items-center justify-center btn-primary w-32 h-10'
        >
          Schedule
        </Link>
      </div>
    </div>
  );
}

function PurchasedGamePacks() {
  const orgId = useMyOrgId();
  const swr = useSWR(orgId ? ['/game-packs', orgId] : null, async () => {
    if (!orgId) return;
    const resp = await apiService.gamePack.getOrgPurchasedGamePacks(orgId);
    return resp.data.gamePacks;
  });

  return (
    <div className='w-245 text-white mt-10'>
      <header className='flex justify-between'>
        <div className='text-3.5xl font-bold'>Purchases</div>
      </header>
      <main className='w-full py-10 items-center justify-center'>
        {match(swr)
          .when(
            () => swr.isLoading,
            () => <Loading />
          )
          .when(
            () => !!swr.error,
            () => (
              <div className='text-sms text-red-002'>{err2s(swr.error)}</div>
            )
          )
          .otherwise(() => (
            <div className='flex flex-col items-center gap-2.5'>
              {swr.data?.map((pack) => (
                <PurchasedGamePackRow key={pack.id} pack={pack} />
              ))}
              {swr.data?.length === 0 && (
                <div>You haven’t purchased any One-Off Experiences yet</div>
              )}
            </div>
          ))}
      </main>
    </div>
  );
}

export function UserEventList(): JSX.Element | null {
  return (
    <div
      className='h-full flex-1 overflow-y-auto scrollbar bg-game-library bg-w-full bg-no-repeat bg-top bg-local 
    flex flex-col items-center'
    >
      <ScheduledEventList />
      <PurchasedGamePacks />
    </div>
  );
}
